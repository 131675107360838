
/**
 * 此文件branch在使用。 downloadGuid也在使用。
 * */ 
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
const { appLanguage = 'en', WEB_CLIENT, REPORT_URL } = gbCommonInfo
import { transformImg } from '@shein/common-function'
const JOURNEY_BRANCH = window.JOURNEY_BRANCH || {}
import { InitFtoken } from 'public/src/pages/common/ftoken/index.js'
import schttp from 'public/src/services/schttp'
let loadingInfo = false
const { SA_REPORT_URL } = REPORT_URL
const BRAND = WEB_CLIENT === 'shein' ? 7 : 9
const LANGUAGE_TO_LOWER_CASE = appLanguage.toLowerCase() // 语言转小写

export default {
  data() {
    return {
      journeies: [],
      banners: [],
      currentJourney: {},
      installed: false,
      fingerKey: '',
      timer: -1,
      reportUrl: `${SA_REPORT_URL}/mdmpMarketingWebReport`,
    }
  },
  methods: {
    /**
     * 获取指纹信息
     */
    calfingerKey() {
      // 指纹id也可以从上面的v-call-app节点中的callApp中获取
      return new Promise((resolve) => {
        InitFtoken((ftoken) => {
          this.fingerKey = ftoken
          resolve(ftoken)
        })
      })
    },

    /**
     * 功能：检查用户是否安装我司app
     * 首次渲染和当用户离开页面再回来时触发
     */
    async setInstalledStatus() {
      const res = await schttp({
        url: '/api/journeyBranch/installed/get',
        method: 'POST',
        data: {
          lang: appLanguage,
          fingerKey: this.fingerKey,
          cid: UserInfoManager.get({ key: 'oest' }),
        },
      })
      this.installed = res.info.status == 1
    },

    async initCheckInstalledStatus() {
      const checkUpdate = async () => {
        await this.calfingerKey()
        await this.setInstalledStatus()
        JOURNEY_BRANCH.installed = this.installed
      }

      if (this.cacheBranch) { // 当branch有缓存时可以直接使用缓存数据的，在异步的进行展示
        this.installed = !!document.querySelector('.j-journey-installed') // 取缓存上的标识，保证和页面展示一致
        JOURNEY_BRANCH.installed = this.installed
        setTimeout(async () => { // 异步执行，不影响主线程
          await checkUpdate()
          appEventCenter.$emit('branchInstallChange', this.installed)
          JOURNEY_BRANCH.installed = this.installed
        })
      } else {
        await checkUpdate()
      }
    },
    
    /**
     * 获取journey信息和branch信息  注意：外部也在调用
     */
    getInfos() {
      return new Promise((resolve) => {
        if (JOURNEY_BRANCH.journeis) {
          this.journeies = JOURNEY_BRANCH.journeis || []
          this.banners = JOURNEY_BRANCH.banners || []
          this.installed = JOURNEY_BRANCH.installed
          return resolve()
        }

        if (loadingInfo) {
          this.timer = setTimeout(async () => {
            await this.getInfos()
            resolve()
          }, 100)
          return
        }

        loadingInfo = true
        Promise.all(
          [schttp({
            url: '/api/journeyBranch/infos/get',
            method: 'GET',
            data: {
              lang: appLanguage,
            }
          }), 
          this.initCheckInstalledStatus()
          ]).then(([res]) => {
          if (res.code != 0 || !res.info) {
            return
          }
          JOURNEY_BRANCH.journeis = this.journeies = res.info.journeis || []
          res.info.banners.forEach((item) => {
            let fileName = transformImg({ img: item.banner_logo_url })
            let suffix = `.${fileName.split('.').pop()}`
            item.banner_logo_url = fileName.replace(suffix, `_thumbnail_126x${suffix}`)
          })
          JOURNEY_BRANCH.banners = this.banners = res.info.banners || []
          loadingInfo = false
          resolve()
        })
      })
    },

    filterBanner(journey) {
      if (!journey || !journey.journey_banners || !journey.journey_banners.length) return
      let bannerName = journey.journey_banners?.[0]?.banner

      const banner = bannerName && this.banners.find((el) => el.brand == BRAND && el.banner_name == bannerName)
      if (banner) {
        const lang = banner.banner_langs.find((el) =>el.language.toLowerCase() == LANGUAGE_TO_LOWER_CASE)
        if (lang) {
          return { ...banner, ...lang }
        }
      }
      return null
    },
    branchBeforeWake() {},
    branchBanerClose() {
      const meta = document.querySelector('meta[name^="branch:deeplink:"]')
      const params = {
        activity_name: 'click_close_M_APP_Banner',
        activity_param: {
          journey_id: this.currentJourney.journey_name,
          deeplink: meta && meta.getAttribute('content') || `${WEB_CLIENT}link://applink/wakeup`,
          finger_print_key: this.fingerKey,
        },
      }
      sa('send', params)
      sa('send', params, {
        _url: this.reportUrl,
      })
    },
    branchBannerShow() {
      const meta = document.querySelector('meta[name^="branch:deeplink:"]')
      const params = {
        activity_name: 'expose_popup_m_app_journey',
        activity_param: {
          journey_id: this.currentJourney.journey_name,
          finger_print_key: this.fingerKey,
          deeplink: meta && meta.getAttribute('content') || `${WEB_CLIENT}link://applink/wakeup`,
          is_installed: this.installed ? '1' : '0'
        },
      }
      sa('send', params)
      sa('send', params, {
        _url: this.reportUrl,
      })
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
}
